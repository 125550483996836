<template>
	<div class='h100 displayFlex flexColumn'>
		<NavbarVue backgroundColor="white" :showBack="true">My Clients</NavbarVue>
		<!-- 查询条件 -->
		<NavbarVue padding="5px 60px">
			<el-form :inline="true" :model="searchParam" class="demo-form-inline" size="medium">
				<el-form-item>
					<el-input v-model="searchParam.fullName" placeholder="Name">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchParam.telMobile" placeholder="Mobile" />
				</el-form-item>
				<el-form-item>
					<el-select v-model="searchParam.birthDate" placeholder="Birthday">
						<el-option v-for="item in BirthdayOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-button @click="getList" size="medium" style="height: 50px;padding: 10px 30px;font-size: 18px;">Search</el-button>
			</el-form>
		</NavbarVue>
		<!-- clients -->
		<div class="" style="padding: 30px 50px;">
			<span style="font-size: 18px;color: #6C6C6C;">Found {{pages.total}} Client(s)</span>
			<!-- 方格 -->
			<div class='displayFlex flexWrap' :style="BoxContent">
				<div class="box m20px displayFlex" v-for="(item, index) in clientBoxContent" :key="index" @click="enterDetail(item.clientNumber)">
					<!-- 方格内容 左：头像 右：名字、电话、邮箱 -->
					<div class="w20 TextAlginCenter pt20px">
						<img :src="imgs[item.gender]" alt="" style="width: 30px;">
					</div>
					<div class="w80 pt20px">
						<p>{{item.fullName}}</p>
						<p style="color: #FA9F52">{{item.grade}}</p>
						<p v-if="item.assessment_outcome.includes('1') || item.assessment_outcome.includes('2')">
							<img :src="require('@/assets/img/cka/CKA.passed.png')" alt="" width="50px" class="mt10px" />
						</p>
						<p>{{item.telMobile}}</p>
						<p>{{item.emailAddress}}</p>
					</div>
				</div>
			</div>
			<PaginationVue :pages="pages" listKey="getList" pageKey="pages" @SetPagination="$SetPagination">
			</PaginationVue>
		</div>
	</div>
</template>

<script>
import NavbarVue from "../../components/Navbar.vue";
import { ClientList } from 'api/My'
import { GET_username } from '../../utils/localStorage';
import PaginationVue from "../../components/Pagination.vue";
export default {
	components: { NavbarVue, PaginationVue },
	data () {
		return {
			BoxContent: {
				height: '600px',
				overflow: 'scroll'
			},
			clientBoxContent: [],
			searchParam: {
				fullName: '',
				telMobile: '',
				birthDate: '',
				comRepCode: GET_username()
			},
			BirthdayOptions: [{
				value: '',
				label: 'All'
			}, {
				value: '10',
				label: '1 Weeks'
			}, {
				value: '20',
				label: '2 Weeks'
			}, {
				value: '30',
				label: '1 Month'
			}, {
				value: '40',
				label: '2 Month'
			}, {
				value: '50',
				label: '3 Month'
			}],
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			imgs: {
				Male: require('@/assets/img/avatar/avatar.mr.png'),
				Female: require('@/assets/img/avatar/avatar.mrs.png'),
			}
		};
	},
	computed: {
		ApiData () {
			return { page_number: String(this.pages.pageNum), page_size: String(this.pages.pageSize), ...this.searchParam }
		},
	},
	watch: {},
	methods: {
		// 动态获取表格高度
		getHeight () {
			this.BoxContent.height = window.innerHeight - 230 + 'px'
		},
		getList () {
			ClientList(this.ApiData).then(res => {
				this.clientBoxContent = res.content.rows
				this.pages.total = res.content.count
			})
		},
		// 进入用户详情页
		enterDetail (item) {
			this.$router.push('/MyDataDetail?code=' + item)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		window.addEventListener('resize', this.getHeight)
		this.getHeight()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getList()
	},
	beforeCreate () { }, //生命周期 - 创建之前
	beforeMount () { }, //生命周期 - 挂载之前
	beforeUpdate () { }, //生命周期 - 更新之前
	updated () { }, //生命周期 - 更新之后
	beforeDestroy () { }, //生命周期 - 销毁之前
	destroyed () { window.removeEventListener('resize', this.getHeight)
	}, //生命周期 - 销毁完成
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
.el-form >>> .el-input__inner {
	height: 50px;
}
/* form表单样式 */
.el-form {
	display: flex;
}
.el-form-item {
	margin-right: 25px;
}
.box {
	border: 2px solid #ebeaeb;
	width: 400px;
	height: 300px;
}
.box p {
	height: 40px;
	font-size: 18px;
	color: #6c6c6c;
}
</style>